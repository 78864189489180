import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, FormattedMessage, useIntl, navigate } from 'gatsby-plugin-intl'

import useUrlParams from 'src/hooks/useUrlParams'
import useValidateSchema from 'src/hooks/useValidateSchema'
import routes from 'src/utils/routes'
import TextInput from 'src/components/atoms/TextInput'
import { Button } from 'src/components/atoms/Buttons'
import { API_STATES } from 'src/ducks/consts'
import SuccessDialog from 'src/features/auth/components/SuccessDialog'
import { Panel, Row, Column } from './components/atoms'
import {
  PanelTitle,
  PanelSubtitle,
  StyledLink,
  PanelAdditionalInformation,
  ErrorMessage
} from './components/typography'
import {
  validateNewPasswordSchema,
  validateNewPassword,
  validateRepeatPassword
} from './duck/schema'
import connect from './duck/connectors'

const NewPassword = ({
  className,
  setNewPassword,
  forgotPasswordState,
  forgotPasswordError,
  changedSuccessfully
}) => {
  const [state, setState] = useState({
    password: '',
    confirmPassword: ''
  })
  const intl = useIntl()
  const params = useUrlParams()
  const valid = useValidateSchema(state, validateNewPasswordSchema)

  const active =
    valid && params.token && forgotPasswordState !== API_STATES.IN_PROGRESS

  const handleSubmit = e => {
    e.preventDefault()
    active &&
      setNewPassword(
        params.token,
        state.password,
        params.username,
        state.confirmPassword
      )
  }

  return (
    <div className={className}>
      <PanelStyled>
        <Column>
          <PanelTitle>
            <FormattedMessage id='forgottenPassword.defineNewPassword' />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage id='forgottenPassword.typePasswordTwice' />
          </PanelSubtitle>
          <form onSubmit={handleSubmit}>
            <PasswordRow>
              <TextInput
                label={intl.formatMessage({ id: 'common.password' })}
                id='password'
                name='password'
                placeholder={intl.formatMessage({ id: 'common.password' })}
                type='password'
                value={state.password}
                onChange={(name, value) =>
                  setState({ ...state, [name]: value })
                }
                validate={validateNewPassword}
              />
            </PasswordRow>
            <PasswordRow>
              <TextInput
                label={intl.formatMessage({
                  id: 'forgottenPassword.retypePassword'
                })}
                id='confirmPassword'
                name='confirmPassword'
                placeholder={intl.formatMessage({
                  id: 'forgottenPassword.retypePassword'
                })}
                type='password'
                value={state.confirmPassword}
                onChange={(name, value) =>
                  setState({ ...state, [name]: value })
                }
                validate={(name, value, callback) =>
                  validateRepeatPassword(
                    {
                      [name]: value,
                      password: state.password
                    },
                    callback
                  )
                }
              />
            </PasswordRow>
            <ForgotPassword align='flex-end'>
              <StyledLink to={routes.SIGN_IN}>
                <FormattedMessage id='common.signIn' />
              </StyledLink>
            </ForgotPassword>
            <Button type='submit' active={active} onClick={handleSubmit}>
              <FormattedMessage id='forgottenPassword.setNewPassword' />
            </Button>
          </form>
          {forgotPasswordError && (
            <ErrorMessage>{forgotPasswordError}</ErrorMessage>
          )}
        </Column>
        <PanelAdditionalInformation>
          <FormattedMessage id='signIn.dontHaveAccountYet' />{' '}
          <Link to={routes.SIGN_UP}>
            <FormattedMessage id='common.register' />
          </Link>
        </PanelAdditionalInformation>
      </PanelStyled>
      <SuccessDialog
        show={changedSuccessfully}
        title={<FormattedMessage id='forgottenPassword.passwordChanged' />}
        message={
          <FormattedMessage id='forgottenPassword.passwordChangedMessage' />
        }
        buttonText={<FormattedMessage id='common.signIn' />}
        onConfirm={() => {
          navigate(routes.SIGN_IN)
        }}
      />
    </div>
  )
}

const StyledNewPassword = styled(NewPassword)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${Button} {
    width: 100%;
  }
`

const PanelStyled = styled(Panel)`
  padding-top: 2.6rem;
`

const PasswordRow = styled(Row)`
  margin-bottom: 0.3rem;
  &:first-of-type {
    margin-bottom: 1.6rem;
  }
`

const ForgotPassword = styled(Row)`
  margin-bottom: 3.2rem;
`

export default connect(StyledNewPassword)
